// IMPORT VENDOR
import $ from 'jquery';
window.$ = window.jQuery = $;
import debounce from 'lodash/debounce';
import { Foundation } from 'foundation-sites/js/foundation.core';
Foundation.addToJquery($);
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';
Foundation.plugin(MediaQuery, 'MediaQuery');
import { DropdownMenu } from 'foundation-sites/js/foundation.dropdownMenu';
Foundation.plugin(DropdownMenu, 'DropdownMenu');
import { AccordionMenu } from 'foundation-sites/js/foundation.accordionMenu';
Foundation.plugin(AccordionMenu, 'AccordionMenu');
import { Reveal } from 'foundation-sites/js/foundation.reveal';
Foundation.plugin(Reveal, 'Reveal');
import AOS from 'aos';
/* import GLightbox from 'glightbox'; */
/* import Swiper from 'swiper'; */

// IMPORT DES COMPONENTS
import { utils } from './components/_utils';
//import { bodyPaddingTop } from './components/_bodyPaddingTop';
import ScrollToLink from './components/_scrollTo';
import { header } from './components/header';

// Pages
import { homePage } from './pages/home';
import { cameleon } from './pages/cameleon';
import { singleRealisation } from './pages/singleRealisation';
import { organigramme } from './pages/organigramme';
import { realisation } from './pages/realisation';
import { actualite } from './pages/actualite';
import { prestation } from './pages/prestation';


// ----------------------------------- DOCUMENT READY -----------------------------------
// --------------------------------------------------------------------------------------
jQuery(function () {
    utils.checkDevice();
    jQuery(window).on('resize', debounce(function () {
        utils.checkDevice();
    }, 100));

    // Init scrollTo class
    let scrollTo = new ScrollToLink();
    scrollTo.init();
    cameleon.readyFn();

    // Padding top de la hauteur du header sur le <body>
    //bodyPaddingTop.init();

    header.readyFn();



    // Execute le script de la page dans laquelle on se trouve
    let bodyClassList = document.getElementsByTagName('body')[0].classList;
    switch (true) {
        case bodyClassList.contains('home'):
            homePage.readyFn();
            break;
        case bodyClassList.contains('single-realisation'):
            singleRealisation.readyFn();
            break;
        case bodyClassList.contains('single-post'):
            singleRealisation.readyFn();
            break;
        case bodyClassList.contains('page-template-page-realisations'):
            realisation.readyFn();
            break;
        case bodyClassList.contains('page-template-page-actualite'):
            actualite.readyFn();
            break;
        case bodyClassList.contains('page-template-page-prestation'):
            prestation.readyFn();
            break;
        case bodyClassList.contains('page-template-page-organigramme'):
            organigramme.readyFn();
            break;
    }

});


// ----------------------------------- WINDOW LOAD --------------------------------------
// --------------------------------------------------------------------------------------
$(window).on('load', function () {
    document.body.classList.add('loaded');


    // Execute AOS
    setTimeout(() => {
        AOS.init({
            once: true,
            anchorPlacement: 'top-center',
            offset: -250,
            duration: 500
        });

        $('[data-aos]').one("transitionend webkitTransitionEnd oTransitionEnd", function () {
            $('[data-aos]').addClass('aos-end');
        });
    }, 100);

});