// --- Vendors ---
import Swiper, {
    Navigation,
    Pagination,
    Autoplay,
    FreeMode,
} from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay, FreeMode]);

// --- Components ---
const organigramme = {

    readyFn: function () {
        let self = this;
        this.swiper();

    },

    swiper: function () {
        const swiper = new Swiper('.swiper', {
            slidesPerView: 1,
            spaceBetween: 10,
            breakpoints: {
                // when window width is >= 320px
                1200: {
                    slidesPerView: 3.2,
                    spaceBetween: 30
                },
            },
            autoplay: {
                delay: 5000,
                disableOnInteraction: true,
            },
        });

    },

}
export { organigramme };