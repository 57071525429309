// --- Vendors ---
import Swiper, {
    Navigation,
    Pagination,
    Autoplay
} from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

// --- Components ---
const realisation = {

    readyFn: function () {
        let self = this;
        this.realisation();

    },

    realisation: function () {

        var secteur_selected = false;
        var prestation_selected = false;

        //CLIC SUR TOUS LES REALS
        $("li.all").on("click", function (e) {
            $(".grid-filtre li").removeClass("selected");
            secteur_selected = false;
            prestation_selected = false;
            $(".cell-realisation").fadeIn("fast");
        });

        //CLICK SUR UN SECTEUR
        $(".cell-filtre-secteur li.secteur").on("click", function (e) {
            secteur_selected = true;
            var filtre_secteur = $(this).attr('data-filter');
            $(".cell-filtre-secteur li.secteur").removeClass("selected");
            $(this).addClass("selected");
            if (prestation_selected == false) {
                $(".cell-realisation").hide("200");
                $("div [data-secteur*='" + filtre_secteur + "']").delay("400").show("200");
            } else {
                var filtre_prestation = $(".cell-filtre-prestation li.selected.prestation").attr('data-filter');
                $(".cell-realisation").hide("200");
                $(".cell-realisation[data-secteur*='" + filtre_secteur + "'][data-prestation*='" + filtre_prestation + "']").fadeIn("fast");
            }
        });

        $(".cell-filtre-secteur select").on("change", function (e) {
            secteur_selected = true;
            var filtre_secteur = $("option:selected", this).val();
            $(".cell-filtre-secteur li.secteur").removeClass("selected");
            $(this).addClass("selected");
            if (prestation_selected == false) {
                $(".cell-realisation").hide("200");
                $("div [data-secteur*='" + filtre_secteur + "']").delay("400").show("200");
            } else {
                var filtre_prestation = $(".cell-filtre-prestation select option:selected").val();
                $(".cell-realisation").hide("200");
                $(".cell-realisation[data-secteur*='" + filtre_secteur + "'][data-prestation*='" + filtre_prestation + "']").fadeIn("fast");
            }
        });

        //CLIC SUR UNE PRESTATION
        $(".cell-filtre-prestation li.prestation").on("click", function (e) {
            prestation_selected = true;
            var filtre_prestation = $(this).attr('data-filter');
            $(".cell-filtre-prestation li.prestation").removeClass("selected");
            $(this).addClass("selected");
            if (secteur_selected == false) {
                $(".cell-realisation").hide("200");
                $(".cell-realisation[data-prestation*='" + filtre_prestation + "']").delay("400").show("200");
            } else {
                var filtre_secteur = $(".cell-filtre-secteur li.selected.secteur").attr('data-filter');
                $(".cell-realisation").hide("200");
                $(".cell-realisation[data-secteur*='" + filtre_secteur + "'][data-prestation*='" + filtre_prestation + "']").fadeIn("fast");
            }
        });

        $(".cell-filtre-prestation select").on("change", function (e) {
            prestation_selected = true;
            var filtre_prestation = $("option:selected", this).val();
            $(".cell-filtre-prestation li.prestation").removeClass("selected");
            $(this).addClass("selected");
            if (secteur_selected == false) {
                $(".cell-realisation").hide("200");
                $(".cell-realisation[data-prestation*='" + filtre_prestation + "']").delay("400").show("200");
            } else {
                var filtre_secteur = $(".cell-filtre-secteur select option:selected").val();
                $(".cell-realisation").hide("200");
                $(".cell-realisation[data-secteur*='" + filtre_secteur + "'][data-prestation*='" + filtre_prestation + "']").fadeIn("fast");
            }
        });

    },

}
export { realisation };