// --- Vendors ---
import Isotope from 'isotope-layout';
var jQueryBridget = require('jquery-bridget');

// --- Components ---
const actualite = {

    readyFn: function () {
        let self = this;
        jQueryBridget('isotope', Isotope, $);
        this.actualite();

    },

    actualite: function () {
        console.log("prouta")
        $(".tag").on("click", function () {
            $(".tag").removeClass("actif");
            $(this).addClass("actif");
        });

        var $gridArticle = $('.grid-article').isotope({
            itemSelector: '.cell-article',
            layoutMode: 'fitRows'
        });

        $(".tag-list").on("click", ".tag", function () {
            var filterValue = $(this).attr("data-filter");
            $gridArticle.isotope({
                filter: filterValue
            });
        });

        $("#select-actu").on("change", function () {
            var filterValue = $("option:selected", this).val();
            $gridArticle.isotope({
                filter: filterValue
            });
        });
    },

}
export { actualite };